import Script from "next/script";
import SearchBox from "@components/main/SearchBox";
import ListA from "@components/main/ListA";
import BannerA from "@components/main/BannerA";
import ListD from "@components/main/ListD";
import ListC from "@components/main/ListC";
import BannerB from "@components/main/BannerB";
import BannerC from "@components/main/BannerC";
import ListE from "@components/main/ListE";
import BannerD from "@components/main/BannerD";
import ListF from "@components/main/ListF";
import BannerE from "@components/main/BannerE";
import FooterLink from "@components/main/FooterLink";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import React, { useEffect, useRef, useState } from "react";
import { getMainSearch } from "@modules/api/Search";
import Layout from "@components/layout";
import { Contains } from "@constants/contains";
import { useRouter } from "next/router";
import CookieConsent from "@components/cookie/CookieConsent";
import { getMyProfile } from "@modules/api/Crew";
import Head from "next/head";
import { useTranslation } from "next-i18next";
import { setCookie } from "cookies-next";
import { convertDomain, getAccessToken, getDomain } from "@modules/utils/AccessToken";
import { useTablet } from "@modules/hooks/useMedia";
import { useDomain } from "@modules/hooks/useDomain";
import TTBBFooterLogo from "@public/images/ttbb/img_ttbb_footer_logo.svg";
import { Cookies } from "react-cookie-consent";
import { IsTTBBApp } from "@modules/utils/TripbtozApp";
import ListG from "@components/main/ListG";
import { datadogRum } from "@datadog/browser-rum";
import SearchBoxV2 from "@components/main/SearchBoxV2";
import EventBanner from "@components/main/EventBanner";
import DevRemoteController from "@components/common/devrc";
import { useAtom } from "jotai/index";
import { mainPageVer2Atom, visibleBigBannerAtom, webMainAtom } from "@store/global";
import { isMobile } from "react-device-detect";
import { PledgeBanner } from "@components/pledge/banner";

/* 다국어 설정 및 서버 데이터 호출 */
export async function getServerSideProps(context) {
  const {
    access_token,
    refresh_token
  } = context.query;
  const {
    req,
    res
  } = context;
  let authorization = context.req.headers["Authorization"];
  if (!authorization) {
    authorization = context.req.headers["authorization"];
  }
  if (access_token && refresh_token) {
    const domain = convertDomain(req.headers["host"]);
    setCookie("token_type", "Bearer", {
      req,
      res,
      path: "/",
      maxAge: 60 * 60 * 24,
      domain: domain
    });
    setCookie("access_token", access_token, {
      req,
      res,
      path: "/",
      maxAge: 60 * 60 * 24,
      domain: domain
    });
    setCookie("refresh_token", refresh_token, {
      req,
      res,
      path: "/",
      maxAge: 60 * 60 * 24 * 90,
      domain: domain
    });
    getMyProfile(context.locale).then(profile => {
      setCookie("user_id", profile?.body.item.id, {
        req,
        res,
        path: "/",
        maxAge: 60 * 60 * 24 * 90,
        domain: domain
      });
    });
  }
  if (authorization) {
    const tokenArr = authorization.split(" ");
    const domain = convertDomain(req.headers["host"]);
    setCookie("token_type", tokenArr[0], {
      req,
      res,
      path: "/",
      maxAge: 60 * 60 * 24,
      domain: domain
    });
    setCookie("access_token", tokenArr[1], {
      req,
      res,
      path: "/",
      maxAge: 60 * 60 * 24,
      domain: domain
    });
    setCookie("refresh_token", tokenArr[2], {
      req,
      res,
      path: "/",
      maxAge: 60 * 60 * 24 * 90,
      domain: domain
    });
  }
  return {
    props: {
      ...(await serverSideTranslations(context.locale, ["common"])),
      locale: context.locale,
      access_token: access_token || null,
      refresh_token: refresh_token || null
    }
  };
}
export default function Main({
  locale,
  access_token,
  refresh_token
}) {
  const router = useRouter();
  const {
    t
  } = useTranslation("common");
  const isTablet = useTablet();
  const isTTBB = useDomain();
  const headerTitle = isTTBB ? "티티비비 TTBB" : `${t("head.title.main")}`;
  const [webMain] = useAtom(webMainAtom);
  const [mainBannerData, setMainBannerData] = useState(null);
  const [openSearchBar, setOpenSearchBar] = useState(false);
  const isLoggedIn = getAccessToken() !== "";
  const [, setIsBigBannerVisible] = useAtom(visibleBigBannerAtom);
  const searchRef = useRef(); // Intersection Observer를 적용할 Ref

  const [devRC, setDevRC] = useState(false); // 개발 테스트용 컨트롤러
  const [mainPageVer2, setMainPageVer2] = useAtom(mainPageVer2Atom); // 신규 메인페이지 분기

  useEffect(() => {
    if (Cookies.get("NEXT_LOCALE")) {
      Cookies.remove("NEXT_LOCALE", {
        domain: getDomain()
      });
    }
    if (!isTTBB) {
      setMainPageVer2(true);
    }
    if (access_token && refresh_token) {
      localStorage.setItem("refresh_token", refresh_token);
      const returnUrl = localStorage.getItem("returnUrl");
      localStorage.removeItem("returnUrl");
      if (returnUrl) {
        router.push(returnUrl);
      } else {
        router.replace(`/`);
      }
    } else {
      localStorage.removeItem("returnUrl");
    }
    return () => {
      setMainPageVer2(false);
    };
  }, []);
  useEffect(() => {
    if (IsTTBBApp()) {
      window.signInResultViaApp = async json => {
        let item = json;
        if (typeof json === "string") {
          item = JSON.parse(json);
        }
        Cookies.set("user_id", item.userId, {
          expires: 1 / 24,
          domain: getDomain()
        });
        datadogRum.setUser({
          id: item.userId
        });
        const tokenArr = item.token.split(" ");
        Cookies.set("token_type", tokenArr[0], {
          expires: 1 / 24,
          domain: getDomain()
        });
        Cookies.set("access_token", tokenArr[1], {
          expires: 1 / 24,
          domain: getDomain()
        });
        Cookies.set("refresh_token", tokenArr[2], {
          expires: 1,
          domain: getDomain()
        });
        router.reload();
      };
    }
  }, []);

  // Intersection Observer 설정
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setIsBigBannerVisible(true);
      } else {
        setIsBigBannerVisible(false);
      }
    }, {
      threshold: 0.1
    });
    if (searchRef.current) {
      observer.observe(searchRef.current); // Ref에 Observer 등록
    }
    return () => {
      if (searchRef.current) {
        // Observer를 해제
        observer.unobserve(searchRef.current);
      }
    };
  }, [mainPageVer2]);
  useEffect(() => {
    getMainSearch(locale).then(data => setMainBannerData({
      ...data.body
    }));

    // 목업 데이터
    // setMainBannerData(mainpageData);
  }, [locale]);

  // 개편 전 meta 코드 삭제
  // useEffect(() => {
  //   if (!router.isReady) return;
  //   const { utm_source, transaction_id } = router.query;
  //   /**
  //    * 샵백(shopback) 관련 코드
  //    */
  //   if ((utm_source === "SHOPBACK" || utm_source === "shopback") && !!transaction_id) {
  //     // 샵백 진입 쿼리
  //     // setSessionStorage("shopback", transaction_id);
  //     Cookies.set("shopback", transaction_id, {
  //       expires: 1 / 24,
  //       domain: getDomain(),
  //     });
  //   }
  //   // /**
  //   //  * 토스페이 관련 코드
  //   //  */
  //   // if (utm_source === AD_TYPE.TOSSPAY_CPS || utm_source === AD_TYPE.TOSSPAY_CPS.toLowerCase()) {
  //   //   setSessionStorage(AD_TYPE.TOSSPAY_CPS.toLowerCase(), AD_TYPE.TOSSPAY_CPS.toLowerCase());
  //   // }
  // }, [router.isReady, router.query]);

  const makeMainPageItems = () => {
    return <>
        {mainBannerData?.items && mainBannerData?.items.map((bannerData, index) => <React.Fragment key={index}>
              {bannerData?.items && bannerData?.items.length > 0 && <>
                  {/* mGroup2 */}
                  {bannerData.type === "LIST_D" && <ListD info={bannerData} keyIndex={index} />}
                  {/* mGroup3 */}
                  {bannerData?.type === "BANNER_A" && <BannerA info={bannerData} />}
                  {/* mGroup4 */}
                  {bannerData?.type === "LIST_A" && <ListA info={bannerData} />}
                  {/* mGroup5 */}
                  {bannerData.type === "LIST_C" && <ListC info={bannerData} />}

                  {/* mGroup7 & kb 글로벌리어배너 */}
                  {bannerData.type === "BANNER_B" && <BannerB info={bannerData} isKb={isTTBB} />}

                  {/* mGroup8 */}
                  {bannerData.type === "BANNER_C" && !isTTBB && <BannerC info={bannerData} />}
                  {/* mGroup9 */}
                  {bannerData.type === "LIST_E" && <ListE info={bannerData} />}
                  {/* mGroup10 */}
                  {bannerData.type === "BANNER_D" && <BannerD info={bannerData} />}
                  {/* mGroup11 */}
                  {bannerData.type === "LIST_F" && !isTTBB && <ListF info={bannerData} />}
                  {/* mGroup6 */}
                  {bannerData.type === "BANNER_E" && <BannerE info={bannerData} />}
                  {/*mGroup13*/}
                  {bannerData?.type === "LIST_G" && <ListG info={bannerData} />}

                  {index === 0 && !isLoggedIn && !isTTBB && isMobile && <div className="relative top-[-40px] px-4">
                      <PledgeBanner />
                    </div>}
                </>}
            </React.Fragment>)}
      </>;
  };
  return <>
      <Head>
        {/*<title>{headerTitle}</title>*/}
        {!isTTBB && <link rel="canonical" href="https://www.tripbtoz.com/" />}
      </Head>
      {!isTTBB && process.env.NEXT_PUBLIC_DEV_ENV !== "development" && <Script id="criteo_viewHome" type="text/javascript" strategy="afterInteractive" dangerouslySetInnerHTML={{
      __html: `window.criteo_q = window.criteo_q || [];
                  var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
                  window.criteo_q.push(
                   { event: "setAccount", account: 109493},
                   { event: "setEmail", email: "", hash_method: "sha256" },
                   { event: "setZipcode", zipcode: "" },
                   { event: "setSiteType", type: deviceType},
                   { event: "viewHome"});`
    }} />}

      {/*메인 앱 전용 혜택 배너*/}
      {!isTTBB && mainPageVer2 && webMain?.banner?.app_benefit && <EventBanner data={webMain?.banner?.app_benefit} />}

      <Layout headerType={Contains.MAIN} wrapClassName={`tablet:pb-[56px]`} openSearchBar={openSearchBar} isHomeBarFixed={true}>
        {isTTBB || isTablet || !mainPageVer2 ? <SearchBox setOpenSearchBar={setOpenSearchBar} /> : <SearchBoxV2 ref={searchRef} data={webMain?.banner?.big_banner} />}
        <div className="relative z-0 min-h-[50dvh]">{makeMainPageItems()}</div>
        {!isTTBB && <FooterLink />}
        {isTablet && isTTBB && <div className="mt-4 flex justify-center">
            <TTBBFooterLogo />
          </div>}
        <CookieConsent />
      </Layout>

      {/*개발 테스트용 RC*/}
      {devRC && <DevRemoteController contents={<div className="flex flex-col">
              <button className="h-6 cursor-pointer" onClick={() => {
        setMainPageVer2(false);
      }}>
                기존 메인 화면
              </button>
              <button className="h-6 cursor-pointer" onClick={() => {
        setMainPageVer2(true);
      }}>
                신규 메인 화면
              </button>
            </div>} callbacks={() => {
      setDevRC(false);
    }} />}
    </>;
}